const routes = {
  index: '/',
  callback: '/callback',
  contentRepositories: '/content-repositories',
  addContentRepository: '/content-repositories/add',
  editContentRepository: '/content-repositories/:repoId?',
  presentations: '/presentation',
  presentationSetup: '/presentation/setup/:presentationId?',
  reportAllPresentations: '/reports/all-presentations',
  reportUserPresentations: '/reports/user-presentations',
  reportUserPresentationsSpike: '/reports/user-presentations-spike',
  reportSlidesUsage: '/reports/slides-usage',
  reportSlideDownloadCounts: '/reports/slide-download-counts',
  reportSlidesByUser: '/reports/slides-by-user',
  reportSlideDetail: '/reports/slide-detail',
  reportPresentationsDownloads: '/reports/presentations-downloads',
  reportSlidesDownloads: '/reports/slides-downloads',
  reportDecksDownloads: '/reports/decks-downloads',
  reportPresentationsCreate: '/reports/presentations-create',
  slideDetails: '/slides/:contentRepoId/:slideId',
  starterDecks: '/starter-decks',
  starterDeckSetup: '/starter-decks/setup/:deckId?',
  starterDeckReorder: '/starter-decks/reorder/:deckId?',
  deckDetails: '/deck/:contentRepoId/:deckId',
  shareSlide: '/share/slide/:contentRepoId/:slideId',
  shareDeck: '/share/deck/:contentRepoId/:deckId',
  presentationDetails: '/presentation/:presentationId',
  toolConfiguration: '/configuration',
  ingestJobs: '/ingest-jobs',
  logout: '/logout',
};

export const documentTitleMap = {
  contentRepositories: 'Content Repositories',
  addContentRepository: 'Content Repositories:Add Content Repository',
  editContentRepository: 'Content Repositories:Edit Content Repository',
  presentations: 'Project Library',
  slideDetailView: 'Slide Detail View',
  deckDetailView: 'Deck Detail View',
  presentationDetailView: 'Presentation Detail View',
  editPresentation: 'Edit Presentation',
  createPresentation: 'Create Presentation',
  starterDecks: 'Starter Decks',
  reorderStarterDecks: 'Reorder Starter Decks',
  createStarterDeck: 'Add Starter Deck',
  editStarterDeck: 'Edit Starter Deck',
  toolConfiguration: 'Tool Configuration',
  ingestJobs: {
    document: 'Ingest Jobs',
    page: 'Ingest Jobs'
  },
  reportAllPresentations: {
    document: 'Reports:All Presentations',
    page: 'All Presentations',
  },
  reportUserPresentations: {
    document: 'Reports:User Presentations',
    page: 'Presentations By User',
  },
  reportUserPresentationsSpike: {
    document: 'Reports:User Presentations (SPIKE)',
    page: 'Presentations By User (SPIKE)',
  },
  reportSlidesUsage: {
    document: 'Reports:Slides Usage',
    page: 'Slide Usage',
  },
  reportSlideDownloadCounts: {
    document: 'Reports:Slide Download Counts',
    page: 'Slide Download Counts',
  },
  reportSlidesDownloads: {
    document: 'Reports:Slides Downloaded',
    page: 'Slides Downloads',
  },
  reportDecksDownloads: {
    document: 'Reports:Decks Downloaded',
    page: 'Decks Downloaded',
  },
  reportSlidesByUser: {
    document: 'Reports:Slides By User',
    page: 'Slides By User',
  },
  reportSlideDetail: {
    document: 'Reports:Slide Detail',
    page: 'Slides Detail',
  },
  reportPresentationsDownload: {
    document: 'Reports:Presentations Download',
    page: 'Presentations Download',
  },
  reportPresentationsDownloads: {
    document: 'Reports:Presentations Downloaded',
    page: 'Presentations Downloaded',
  },
  reportPresentationsCreate: {
    document: 'Reports:Presentations Created',
    page: 'Presentations Created',
  },
};
export default routes;
